import React from 'react';
import './SafeSwapsPage.scss';

const SafeSwapsPageComponent = () => {
    return (
        <section className="SafeSwapsPageComponent">COMING SOON</section>
    );
};

export default SafeSwapsPageComponent;
