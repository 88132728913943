import React from 'react';
import './Homepage.scss';

const HomepageComponent = () => {
    return (
        <section className="HomepageComponent">COMING SOON</section>
    );
};

export default HomepageComponent;
